<template>
  <div class="card p-shadow-6">
    <Loading :active.sync="loading" :is-full-page="true"></Loading>
    <h1>Férias da equipa</h1>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-6 p-md-3">
        <label>Departamento</label>
        <Dropdown
          :class="'form-control'"
          v-model="departmentSelected"
          :options="departmentsList"
          :filter="true"
          @input="updateVacations"
        />
      </div>
      <div class="p-field p-col-6 p-md-2">
        <label for="year-selector">Ano</label>
        <InputNumber
          id="year-selector"
          name="year-selector"
          :value="year"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          :step="1"
          :min="new Date().getFullYear() - 10"
          :max="new Date().getFullYear()"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
    </div>
    <timeline ref="timeline" :items="items" :groups="groups" :options="options">
    </timeline>

    <div v-bind:class="[{ middle: !$isMobile() }, 'p-col-12', 'p-md-12']">
      <div v-bind:class="[{ middle: !$isMobile() }, 'p-col-12', 'p-md-1']">
        <div><b>Legenda:</b></div>
      </div>

      <div v-bind:class="[{ middle: !$isMobile() }, 'p-col-12', 'p-md-2']">
        <div class="dot holidays"></div>
        Feriado Nacional
      </div>
      <div v-bind:class="[{ middle: !$isMobile() }, 'p-col-12', 'p-md-2']">
        <div class="dot holidays-local"></div>
        Feriado Municipal
      </div>
      <div v-bind:class="[{ middle: !$isMobile() }, 'p-col-12', 'p-md-2']">
        <div class="dot weekdays"></div>
        Fim de Semana
      </div>
    </div>
  </div>
</template>

<script>
import utilitiesService from "../services/utilities.service";
import holidaysService from "../services/holidays.service";
import vacationsService from "../services/vacations.service";
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "VacationsDepartments",
  components: {
    Loading: loading,
  },
  data() {
    return {
      departmentsList: [],
      namesList: [],
      departmentSelected: null,
      year: new Date().getFullYear(),
      loading: false,
      groups: [{ id: 0, content: "" }],
      items: [],
      options: {
        editable: false,
        locale: "pt",
        min: new Date(`${new Date().getFullYear()}-01-01`),
        max: new Date(`${new Date().getFullYear()}-12-31`),
        timeAxis: { scale: "day", step: 1 },
        zoomMin: 86400000 * (this.$isMobile() ? 7 : 31),
        // zoomMax: 86400000 * 31,
        showMajorLabels: true,
        showMinorLabels: true,
        align: "center",
        orientation: "both",
        format: {
          minorLabels: {
            day: "D",
          },
        },
      },
    };
  },
  beforeCreate() {
    utilitiesService.getDepartments().then((response) => {
      if (response) {
        this.departmentsList = response;
        this.departmentsList.push("Todos os departamentos");
      }
    });
  },
  created() {
    this.departmentSelected = this.$store.state.auth.user.department;
    this.updateVacations();
  },
  methods: {
    yearChanged(newYear) {
      if (newYear != this.year) {
        this.year = newYear;
        this.options.min = new Date(`${newYear}-01-01`);
        this.options.max = new Date(`${newYear}-12-31`);
        this.updateVacations();
      }
    },
    getHolidays(year) {
      return holidaysService.getAllYearHolidays(year).then((response) => {
        response.forEach((holiday) => {
          this.groups.forEach((userInfo) => {
            let color;
            if (holiday.local == "") {
              color = "#0b5ed7";
            } else if (
              this.namesList[userInfo.id] != undefined &&
              holiday.local == this.namesList[userInfo.id].office
            ) {
              color = "#bee3f8";
            }

            if (color != undefined) {
              this.items.push({
                group: userInfo.id,
                start: new Date(`${holiday.day} 00:00:00`),
                end: new Date(`${holiday.day} 23:59:59`),
                content: "",
                type: "background",
                style: `background-color: ${color}`,
              });
            }
          });
        });
      });
    },
    updateVacations() {
      this.loading = true;
      this.holidaysList = [];
      this.namesList = [];
      this.items = [];
      this.groups = [{ id: 0, content: "" }];
      let departement = undefined;
      if (this.departmentSelected != "Todos os departamentos") {
        departement = this.departmentSelected;
      }
      return vacationsService
        .getDepartementVacations(this.year - 1, departement)
        .then((response) => {
          response.forEach((vacation) => {
            if (this.namesList[vacation.user_id] === undefined) {
              this.groups.push({
                id: vacation.user_id,
                content: vacation.username,
              });

              this.namesList[vacation.user_id] = {
                user_id: vacation.user_id,
                username: vacation.username,
                color: vacation.color,
                department: vacation.department,
                office: vacation.office,
              };
            }

            this.items.push({
              group: vacation.user_id,
              start: new Date(`${vacation.day} 00:00:00`),
              end: new Date(`${vacation.day} 23:59:59`),
              content: "",
              type: "background",
              style: `background-color: ${vacation.color}`,
            });
          });
        })
        .then(() => this.getHolidays(this.year))
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.dot {
  height: 25px;
  width: 25px;

  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-right: 3px;
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.weekdays {
  background-color: #edf2f7;
}
.holidays {
  background-color: #0b5ed7;
}
.holidays-local {
  background-color: #bee3f8;
}

.vc-day {
  border-style: solid;
  border-width: 0.05px;
  border-color: #cbd5e0;
}

.cursor-pointer {
  cursor: pointer;
}

.vis-saturday,
.vis-sunday {
  background-color: #edf2f7;
}
</style>
