import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class HolidaysService {
  getHolidays(userId) {
    return axios
      .get(
        `${config["apiUrl"]}nationalHoliday/${userId}`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      }).catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getYearHolidays(userId, year) {
    return axios
      .get(
        `${config["apiUrl"]}nationalHoliday/${userId}/year/${year}`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      }).catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getAllYearHolidays(year) {
    return axios
      .get(
        `${config["apiUrl"]}nationalHoliday/year/${year}`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      }).catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

}
export default new HolidaysService();
