import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class VacationsService {
  getVacationsByYear(userId, year) {
    return axios
      .get(`${config["apiUrl"]}vacations/user/${userId}/year/${year}`, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getVacationsByYearList(userId, year) {
    return axios
      .get(`${config["apiUrl"]}vacations/list/user/${userId}/year/${year}`, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  deleteVacation(userId, dateStart, dateEnd) {
    return axios
      .delete(
        `${config["apiUrl"]}vacations/user/${userId}/start/${dateStart}/end/${dateEnd}`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createVacation(userId, dateStart, dateEnd, year) {
    let bodyParms = {
      start: dateStart,
      end: dateEnd
    };
    return axios
      .post(
        `${config["apiUrl"]}vacations/user/${userId}/year/${year}`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateVacation(userId, oldStart, oldEnd, newStart, newEnd) {
    let bodyParms = {
      new_start: newStart,
      new_end: newEnd
    };
    return axios
      .put(
        `${config["apiUrl"]}vacations/user/${userId}/start/${oldStart}/end/${oldEnd}`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getVacationsNotApproveList(year, userId) {
    let url = `${config["apiUrl"]}vacations/list_not_approved/year/${year}`;

    if (userId != undefined) {
      url += `/user/${userId}`;
    }
    return axios
      .get(url, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getVacationsApproveList(year, userId) {
    let url = `${config["apiUrl"]}vacations/list_approved/year/${year}`;

    if (userId != undefined) {
      url += `/user/${userId}`;
    }
    return axios
      .get(url, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  approveVacation(userId, start, end, approveStatus) {
    let bodyParms = {};
    return axios
      .put(
        `${config["apiUrl"]}vacations/user/${userId}/start/${start}/end/${end}/approved/${approveStatus}`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getDepartementVacations(year, departement) {
    let url = `${config["apiUrl"]}vacations/year/${year}`;

    if (departement != undefined) {
      url += `/department/${departement}`;
    }
    return axios
      .get(url, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new VacationsService();
